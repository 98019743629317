import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { Modal } from "bootstrap";
import debounce from "debounce";
import { webView, webviewEvents } from "../../../variables/webView";

const storageTypes = [
  "storage_100mb_month",
  "storage_100mb_year",
  "storage_300mb_month",
  "storage_300mb_year",
  "storage_500mb_month",
  "storage_500mb_year",
  "storage_1gb_month",
  "storage_1gb_year",
  "storage_10gb_month",
  "storage_10gb_year",
  "storage_100gb_month",
  "storage_100gb_year",
  "storage_1tb_month",
  "storage_1tb_year",
  "storage_10tb_month",
  "storage_10tb_year",
];
export default class extends Controller {
  static values = {
    multisub: Boolean,
    typeText: String,
    notFound: String,
  };

  QRCodeStyling = null;
  plansData = {};
  coupon = "";
  promocodeInfo = {};
  storagePrice = 0;
  storageMultiSub = {
    monthType: null,
    yearType: null,
    monthKey: "",
    yearKey: "",
  };

  modalPrice = {};
  liteMiddlewareUrl = "";
  currentStoragePlan = undefined;
  currentCurrency = "usd";

  symbol = {
    usd: "$",
    aud: "A$",
    brl: "R$",
    eur: "€",
    gbp: "£",
    idr: "Rp",
    inr: "₹",
    sar: "SR",
    thb: "฿",
    uah: "₴",
    vnd: "₫",
  };

  isModalDataLoading = false;
  currentStepModalData = 1;
  totalStepCountModalData = 1;
  isInitModalAsSubscribeMode = true;

  static targets = [
    "premiumYear",
    "liteYear",
    "premium",
    "lite",
    "currencySelect",
    "chooseModal",
    "wrongPromo",
    "noActivePromo",
    "clickableRow",
    "errorManage",
    "liteQrSelectModal",
    "changeQrErr",
    "annually",
    "monthly",
    "annuallyLabel",
    "monthlyLabel",
    "promoForm",
    "storageMB",
    "storagePrice",
    "storageRange",
    "storageUsed",
    "saveLite",
    "savePremium",
    "storageWarning",
    "storageDanger",
    "cancelSubscription",
    "promoField",
    "editPromoForm",
    "yearCostBtn",
    "monthCostBtn",
    "storageMonthYear",
    "switchYearMonth",
    "storageType",
    "storageChoice",
    "extendStorageBtn",
    "storageTariffPrice",
    "changeQrLiteBtn",
    "buyLiteBtn",
    "selectPlan",
    "selectPeriod",
    "selectPlanPrice",
    "storageMultiSubPrice",
    "selectStoragePeriod",
    "subscribeMultisubBtn",
    "selectPlanPriceWithoutCoupon",
    "modalSearch",
  ];

  chooseQrModal = new Modal(this.chooseModalTarget);
  liteQrSelectModal = new Modal(this.liteQrSelectModalTarget);
  cancelSubscriptionModal = new Modal(
    document.getElementById("cancelSubscription")
  );
  qrOrderIdLiteModal = null;
  qrIdLiteModal = null;

  async connect() {
    this.checkModalScroll();
    this.changeMultisubPlan();
    await axios("/get-all-plans-with-storage").then(({ data }) => {
      this.plansData = data;
      this.setData("usd");
    });
    this.setStorageTariffPrice();
    this.currencySelectTarget.value = "usd";
    const liteQrSelectModal = document.getElementById("lite-qr-select");
    liteQrSelectModal.addEventListener("hidden.bs.modal", () => {
      this.resetLiteQrSelectModal();
    });
    const usedStoragePercents =
      (this.storageRangeTarget.value * 100) / this.storageRangeTarget.max;
    this.storageUsedTarget.innerText = usedStoragePercents.toFixed(2);
    if (usedStoragePercents >= 90) {
      this.storageDangerTarget.classList.remove("d-none");
      this.storageRangeTarget.style.setProperty("--color", `#F44336`);
    } else if (usedStoragePercents >= 80) {
      this.storageWarningTarget.classList.remove("d-none");
      this.storageRangeTarget.style.setProperty("--color", `#E1B02F`);
    }
    this.resetStorageSlider();
    this.resetPlanSwitch();

    this.QRCodeStyling = (await import("qr-code-styler")).default;
    this.searchQr = await debounce(this.searchQr.bind(this), 1000);
  }

  resetPlanSwitch() {
    this.annuallyLabelTarget.classList.add("fw-bold");
    this.annuallyLabelTarget.classList.add("text-gray");
    this.annuallyLabelTarget.classList.add("text-dark");
    this.monthlyLabelTarget.classList.remove("fw-bold");
    this.monthlyLabelTarget.classList.remove("text-gray");
    this.monthlyLabelTarget.classList.remove("text-dark");
    this.switchYearMonthTarget.checked = false;
    this.switchYearMonthTarget.removeAttribute("checked");
  }

  setStorageTariffPrice() {
    const storageTariffPrice = this.storageTariffPriceTarget;
    const storagePrice = storageTariffPrice.dataset.tariff
      ? this.plansData[storageTariffPrice.dataset.tariff].find(
          (item) => item.currency === this.currentCurrency
        ).amount
      : 0;
    storageTariffPrice.innerHTML = `${
      this.symbol[this.currentCurrency]
    }${storagePrice}`;
  }

  setData(currency) {
    this.liteYearTargets.forEach((item) =>
      this.setPrice(item, this.plansData["lite_year"], currency)
    );
    this.premiumYearTargets.forEach((item) =>
      this.setPrice(item, this.plansData["premium_year"], currency)
    );
    this.liteTargets.forEach((item) =>
      this.setPrice(item, this.plansData["lite_month"], currency)
    );
    this.premiumTargets.forEach((item) =>
      this.setPrice(item, this.plansData["premium_month"], currency)
    );
    this.calculateSavePrice(currency);
  }

  calculateSavePrice(currency) {
    const liteAmountYear = this.plansData["lite_year"].find(
      (item) => item.currency === currency
    ).amount;
    const liteAmountMonth = this.plansData["lite_month"].find(
      (item) => item.currency === currency
    ).amount;
    const amountLite = (liteAmountMonth * 12 - liteAmountYear).toFixed();
    this.saveLiteTarget.innerHTML = `${this.symbol[currency]}${amountLite}`;

    const premiumAmountYear = this.plansData["premium_year"].find(
      (item) => item.currency === currency
    ).amount;
    const premiumAmountMonth = this.plansData["premium_month"].find(
      (item) => item.currency === currency
    ).amount;
    const amountPremium = (
      premiumAmountMonth * 12 -
      premiumAmountYear
    ).toFixed();
    this.savePremiumTarget.innerHTML = `${this.symbol[currency]}${amountPremium}`;
  }

  setPrice(target, data, currency) {
    const price = data.find((item) => item.currency === currency).amount;
    target.innerHTML = `${this.symbol[currency]}${price}`;
  }

  selectCurrency(e) {
    this.resetPremiumCouponLink();
    this.setData(e.target.value);
    this.currentCurrency = e.target.value;
    this.resetStorageSlider();
    this.setStorageTariffPrice();
    this.changeMultisubPlan();
    this.updateCouponPrices();
  }

  resetPremiumCouponLink() {
    if (this.multisubValue) {
      return;
    }
    const couponStrToRemove = `&coupon=${this.coupon}`;
    this.yearCostBtnTarget.dataset.middlewareUrl =
      this.yearCostBtnTarget.dataset.middlewareUrl.replace(
        couponStrToRemove,
        ""
      );
    this.monthCostBtnTarget.dataset.middlewareUrl =
      this.monthCostBtnTarget.dataset.middlewareUrl.replace(
        couponStrToRemove,
        ""
      );
  }

  getPrice(data) {
    const price = data.find(
      (item) => item.currency === this.currencySelectTarget.value
    ).amount;
    return {
      symbol: this.symbol[this.currencySelectTarget.value],
      amount: price,
    };
  }

  buyLightSubscription(e) {
    if (webView && webviewEvents) {
      window.ReactNativeWebView.postMessage("app_subscription_lite");
    }
    this.liteMiddlewareUrl = e.currentTarget.dataset.middlewareUrl;
    this.checkoutLite();
  }

  showLiteModal(e) {
    if (webView && webviewEvents) {
      window.ReactNativeWebView.postMessage("app_subscription_lite");
    }
    this.isInitModalAsSubscribeMode = true;
    this.liteMiddlewareUrl = e.currentTarget.dataset.middlewareUrl;
    let loaded = this.liteQrSelectModalTarget.dataset.loaded;
    if (loaded != 1) {
      this.loadQrSelectModalContent({ qrId: null, isChangeQrAction: false });
    } else {
      this.buyLiteBtnTarget.classList.remove("d-none");
    }
  }

  checkoutLite() {
    let coupon = "";
    const value = this.promocodeInfo?.value;
    const isActive = this.promocodeInfo?.isActive;
    const limit = this.promocodeInfo?.limit;
    if (value && isActive && limit > 0) {
      if (
        (/type=lite_year/.test(this.liteMiddlewareUrl) &&
          this.promocodeInfo.products.lite_year) ||
        (/type=lite_month/.test(this.liteMiddlewareUrl) &&
          this.promocodeInfo.products.lite_month)
      ) {
        coupon = this.coupon ? `&coupon=${this.coupon}` : "";
      }
    }
    window.location.href = `${this.liteMiddlewareUrl.replace(
      "/null?",
      `/${this.qrIdLiteModal}?`
    )}${coupon}&currency=${this.currentCurrency}`;
  }

  checkout(e) {
    if (webView && webviewEvents) {
      window.ReactNativeWebView.postMessage("app_subscription_premium");
    }
    window.location.href =
      e.currentTarget.dataset.middlewareUrl +
      `&currency=${this.currentCurrency}`;
  }

  async manageSubscription(e) {
    const dataset = e.target.dataset;
    let test = this.getCookie("test_payment");
    if (test === undefined) {
      test = 0;
    } else {
      test = parseInt(test);
    }
    let data = new FormData();
    data.append("order_id", dataset.order);
    data.append("test", test);
    data.append("return_url", dataset.adminUrl);
    data.append("ajax_mode", 1);
    data.append("plan_id", dataset.plan);
    try {
      this.errorManageTarget.classList.add("d-none");
      const { data: response } = await axios.post(dataset.url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.hasOwnProperty("url")) {
        window.location.href = response.url;
      } else {
        this.errorManageTarget.classList.remove("d-none");
      }
    } catch (err) {
      this.errorManageTarget.classList.remove("d-none");
    }
    this.cancelSubscriptionModal.hide();
  }

  async cancelSubscription(e) {
    const dataset = e.target.dataset;
    this.errorManageTarget.classList.add("d-none");
    e.target.classList.add("disabled");
    try {
      const { data } = await axios.post(dataset.url, {
        order_id: dataset.order,
        user_token: dataset.userToken,
        order_type: dataset.plan,
      });
      if (!!data.ok) {
        window.location.reload();
      } else {
        this.errorManageTarget.classList.remove("d-none");
      }
    } catch (err) {
      this.errorManageTarget.classList.remove("d-none");
    } finally {
      e.target.classList.remove("disabled");
    }
    this.cancelSubscriptionModal.hide();
  }

  getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [key, value] = el.split("=");
      cookie[key.trim()] = value;
    });
    return cookie[cookieName];
  }

  liteQrSelect(e) {
    this.isInitModalAsSubscribeMode = false;
    let loaded = this.liteQrSelectModalTarget.dataset.loaded;
    const qrId = e.currentTarget.dataset.qrId;
    const qrOrderId = e.currentTarget.dataset.qrOrderId;
    this.qrOrderIdLiteModal = qrOrderId;
    this.qrIdLiteModal = qrId;
    if (loaded != 1) {
      this.loadQrSelectModalContent({ qrId, isChangeQrAction: true });
    } else {
      this.changeQrLiteBtnTarget.classList.remove("d-none");
      const qrItems = document.querySelectorAll(".qr-item");
      qrItems.forEach((item) => {
        if (qrId == item.dataset.qrId) {
          const inputElement = item.querySelector("input");
          inputElement.checked = true;
          inputElement.setAttribute("checked", "");
          item.classList.add("border-primary");
          this.qrIdLiteModal = item.dataset.qrId;
        }
      });
    }
  }

  async loadQrSelectModalContent({ qrId, isChangeQrAction, searchParam }) {
    const actionButton = isChangeQrAction
      ? this.changeQrLiteBtnTarget
      : this.buyLiteBtnTarget;
    actionButton.classList.remove("d-none");

    const modalBody = this.liteQrSelectModalTarget.querySelector(
      "#qrSelectLiteContent"
    );
    if (this.currentStepModalData === 1) {
      modalBody.innerHTML = `<div class="d-flex justify-content-center align-items-center" style="height: 200px;" id="loadingSpinner">
        <div class="spinner-border text-gray" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>`;
    }

    this.isModalDataLoading = true;
    try {
      const { data } = await axios(
        `/ajax/entry/user-entry-data?revert_result=1&step=${
          this.currentStepModalData
        }${searchParam ? `&entry_title=${searchParam}` : ""}`
      );
      this.modalSearchTarget.classList.remove("d-none");
      if (
        this.currentStepModalData === 1 ||
        this.liteQrSelectModalTarget.dataset.loaded != 1
      ) {
        modalBody.innerHTML = "";
      }
      this.currentStepModalData += 1;
      this.totalStepCountModalData = data.pagination.totalStepCount;

      if (!data.data.length) {
        modalBody.innerHTML = `<div class="text-center my-4 text-gray">${this.notFoundValue}</div>`;
        return;
      }

      data.data.forEach((item) => {
        modalBody.innerHTML += `<div class="qr-item form-check border rounded-1 mb-3 p-2 d-flex align-items-center ${
          qrId == item.id ? "border-primary" : ""
        }" data-qr-id='${item.id}'>
        <label class="form-check-label ps-3" for="flexRadioDefault${item.id}">
          <div class="row align-items-center">
            <div class="col-1">
              <input class="form-check-input m-0 p-0" type="radio" name="flexRadioDefault" id="flexRadioDefault${
                item.id
              }" ${qrId == item.id ? "checked" : ""}>
            </div>
            <div class="col-3">
              ${
                item.image
                  ? `<div class="d-flex justify-content-center align-items-center h-100""><img src="${item.image}" class="w-100"></div>`
                  : `<div class="qrcode d-flex justify-content-center align-items-center h-100" data-link="${item.link}"></div>`
              }
            </div>
            <div class="col-8">
              <div class="fw-bold">${item.title}</div>
              <div class="overflow-hidden">
                  <span class="text-gray me-2">URL:</span>
                  <span>${item.link}</span>
              </div>
              <div>
                  <span class="text-gray me-2">${this.typeTextValue}:</span>
                  <span>${item.type}</span>
              </div>
            </div>
          </div>
      </label>
  </div>`;
      });

      modalBody.querySelectorAll(".qrcode").forEach((item) => {
        item.innerHTML = "";
        let newQr = new this.QRCodeStyling({ data: item.dataset.link });
        newQr.append(item);
      });

      this.liteQrSelectModalTarget.dataset.loaded = 1;
      const qrItems = document.querySelectorAll(".qr-item");
      qrItems.forEach((qrItem) => {
        qrItem.addEventListener("click", (e) => {
          qrItems.forEach((item) => {
            item.classList.remove("border-primary");
            const input = item.querySelector("input");
            input.removeAttribute("checked");
          });
          e.currentTarget.classList.add("border-primary");
          const input = e.currentTarget.querySelector("input");
          input.checked = true;
          input.setAttribute("checked", "");
          this.qrIdLiteModal = qrItem.dataset.qrId;
        });
      });
    } catch (err) {
    } finally {
      this.loadingSpinnerShow(false);
      this.isModalDataLoading = false;
    }
  }

  checkModalScroll() {
    const modalContent = document.querySelector("#qrSelectLiteContent");
    modalContent.addEventListener("scroll", () => {
      if (
        !this.isModalDataLoading &&
        this.currentStepModalData <= this.totalStepCountModalData &&
        modalContent.scrollHeight - modalContent.scrollTop <=
          modalContent.clientHeight + 100
      ) {
        this.loadingSpinnerShow(true);
        this.loadQrSelectModalContent({
          qrId: this.isInitModalAsSubscribeMode ? null : this.qrIdLiteModal,
          isChangeQrAction: !this.isInitModalAsSubscribeMode,
        });
      }
    });
  }

  loadingSpinnerShow(isShow) {
    const modalContent = document.querySelector("#qrSelectLiteContent");
    let newDiv = document.createElement("div");
    newDiv.innerHTML = `<div class="text-center" id="loadingSpinner">
    <div class="spinner-border text-gray" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>`;
    if (isShow) {
      modalContent.appendChild(newDiv);
    } else {
      const loadingSpinner = modalContent.querySelector("#loadingSpinner");
      loadingSpinner?.parentNode.removeChild(loadingSpinner);
    }
  }

  async saveLiteQrSelect() {
    try {
      await axios.post(
        `/subscription/change-lite/order/${this.qrOrderIdLiteModal}/qr/${this.qrIdLiteModal}/`
      );
      this.liteQrSelectModal.hide();
      window.location.reload();
    } catch (err) {
      this.showErrorMsg(this.changeQrErrTarget);
    }
  }

  resetLiteQrSelectModal() {
    this.qrOrderIdLiteModal = null;
    this.qrIdLiteModal = null;
    this.changeQrLiteBtnTarget.classList.add("d-none");
    this.buyLiteBtnTarget.classList.add("d-none");
    const qrItems = document.querySelectorAll(".qr-item");
    qrItems.forEach((item) => {
      const inputElement = item.querySelector("input");
      inputElement.removeAttribute("checked");
      inputElement.checked = false;
      item.classList.remove("border-primary");
    });
  }

  showErrorMsg(target) {
    target.classList.remove("d-none");
    setTimeout(() => target.classList.add("d-none"), 5000);
  }

  switchPlans(e) {
    this.toggleSwitchPlans(e.target.checked);
  }

  toggleSwitchPlans(isToggle) {
    const action = isToggle ? "add" : "remove";
    const reverseAction = isToggle ? "remove" : "add";
    this.monthlyLabelTarget.classList[action](
      "fw-bold",
      "text-gray",
      "text-dark"
    );
    this.annuallyLabelTarget.classList[reverseAction](
      "fw-bold",
      "text-gray",
      "text-dark"
    );
    this.monthlyTargets.forEach((item) => {
      item.classList[reverseAction]("d-none");
    });
    this.annuallyTargets.forEach((item) => {
      item.classList[action]("d-none");
    });
    this.storageMonthYearTargets.forEach(
      (item) =>
        (item.innerHTML =
          this.storageMonthYearTarget.dataset[isToggle ? "month" : "year"])
    );
    this.resetStorageSlider();
  }

  resetStorageSlider() {
    this.storageChoiceTargets.forEach((item) => {
      item.value = 0;
    });
    this.storagePriceTargets.forEach(
      (item) => (item.innerHTML = `${this.symbol[this.currentCurrency]}${0}`)
    );
    this.currentStoragePlan = undefined;
    this.storageMBTargets.forEach((item) => (item.innerHTML = 0));
    this.extendStorageBtnTargets.forEach((item) => (item.disabled = true));
    this.storageMultiSubPriceTargets.forEach(
      (item) => (item.innerHTML = `${this.symbol[this.currentCurrency]}${0}`)
    );
    this.storageMultiSub = {
      monthType: null,
      yearType: null,
      monthKey: "",
      yearKey: "",
    };
  }

  showPromo() {
    this.promoFormTarget.classList.toggle("d-none");
  }
  // handleDetails(e) {
  //   e.currentTarget.querySelector("span").innerHTML =
  //     e.currentTarget.innerText.trim() === e.currentTarget.dataset.hideText
  //       ? e.currentTarget.dataset.showText
  //       : e.currentTarget.dataset.hideText;
  //   e.currentTarget.previousElementSibling.classList.toggle("d-none");
  // }
  handleDetails(e) {
    const target = e.currentTarget;
    const contentSpan = target.querySelector("span");
    const imgElement = target.querySelector(".tariff-details-img");

    contentSpan.innerHTML =
      target.innerText.trim() === target.dataset.hideText
        ? target.dataset.showText
        : target.dataset.hideText;

    target.previousElementSibling.classList.toggle("d-none");
    if (!target.previousElementSibling.classList.contains("d-none")) {
      imgElement.classList.add("rotate");
    } else {
      imgElement.classList.remove("rotate");
    }
  }

  changeSlider(e) {
    this.storageChoiceTargets.forEach((item) => {
      item.value = e.target.value;
    });
    let storagePlan;
    let price;
    const isMonth = this.switchYearMonthTarget.checked;
    let storageMultiSubKeys = ["", ""];
    switch (e.target.value) {
      case "0":
        storagePlan = 0;
        price = 0;
        storageMultiSubKeys = ["", ""];
        break;
      case "1":
        storagePlan = 100;
        storageMultiSubKeys = [storageTypes[0], storageTypes[1]];
        price = this.plansData[
          `${isMonth ? storageTypes[0] : storageTypes[1]}`
        ].find((item) => item.currency === this.currentCurrency).amount;
        break;
      case "2":
        storagePlan = 300;
        storageMultiSubKeys = [storageTypes[2], storageTypes[3]];
        price = this.plansData[
          `${isMonth ? storageTypes[2] : storageTypes[3]}`
        ].find((item) => item.currency === this.currentCurrency).amount;
        break;
      case "3":
        storagePlan = 500;
        storageMultiSubKeys = [storageTypes[4], storageTypes[5]];
        price = this.plansData[
          `${isMonth ? storageTypes[4] : storageTypes[5]}`
        ].find((item) => item.currency === this.currentCurrency).amount;
        break;
      case "4":
        storagePlan = 1000;
        storageMultiSubKeys = [storageTypes[6], storageTypes[7]];
        price = this.plansData[
          `${isMonth ? storageTypes[6] : storageTypes[7]}`
        ].find((item) => item.currency === this.currentCurrency).amount;
        break;
      case "5":
        storagePlan = 10000;
        storageMultiSubKeys = [storageTypes[8], storageTypes[9]];
        price = this.plansData[
          `${isMonth ? storageTypes[8] : storageTypes[9]}`
        ].find((item) => item.currency === this.currentCurrency).amount;
        break;
      case "6":
        storagePlan = 100000;
        storageMultiSubKeys = [storageTypes[10], storageTypes[11]];
        price = this.plansData[
          `${isMonth ? storageTypes[10] : storageTypes[11]}`
        ].find((item) => item.currency === this.currentCurrency).amount;
        break;
      case "7":
        storagePlan = 1000000;
        storageMultiSubKeys = [storageTypes[12], storageTypes[13]];
        price = this.plansData[
          `${isMonth ? storageTypes[12] : storageTypes[13]}`
        ].find((item) => item.currency === this.currentCurrency).amount;
        break;
      case "8":
        storagePlan = 10000000;
        storageMultiSubKeys = [storageTypes[14], storageTypes[15]];
        price = this.plansData[
          `${isMonth ? storageTypes[14] : storageTypes[15]}`
        ].find((item) => item.currency === this.currentCurrency).amount;
        break;
      default:
        break;
    }
    this.storagePrice = price;
    this.storageMultiSub = {
      monthType:
        this.storageTypeTarget.children[Number(e.target.value)]?.dataset
          .monthType,
      yearType:
        this.storageTypeTarget.children[Number(e.target.value)]?.dataset
          .yearType,
      monthKey: storageMultiSubKeys[0],
      yearKey: storageMultiSubKeys[1],
    };
    this.extendStorageBtnTargets.forEach(
      (item) => (item.disabled = Number(e.target.value) === 0)
    );
    this.currentStoragePlan =
      this.storageTypeTarget.children[Number(e.target.value)]?.dataset[
        isMonth ? "monthType" : "yearType"
      ];
    this.storageMBTargets.forEach((item) => (item.innerHTML = storagePlan));
    this.storagePriceTargets.forEach(
      (item) =>
        (item.innerHTML = `${this.symbol[this.currentCurrency]}${price}`)
    );
    if (this.multisubValue) {
      this.changeStorageMultiSub();
    }
  }

  extendStorage(e) {
    if (!this.currentStoragePlan) {
      return;
    }
    const linkWithCurrency = `${e.currentTarget.dataset.middlewareUrl}?currency=${this.currentCurrency}`;
    if (this.multisubValue) {
      const multisubQuantity =
        this.selectStoragePeriodTarget.options[
          this.selectStoragePeriodTarget.selectedIndex
        ].dataset.quantity;

      const type =
        this.storageMultiSub[
          `${
            this.selectStoragePeriodTarget.options[
              this.selectStoragePeriodTarget.selectedIndex
            ].dataset.type
          }Type`
        ];
      window.location.href = `${linkWithCurrency}&type=${type}&quantity=${multisubQuantity}`;
    } else {
      window.location.href = `${linkWithCurrency}&type=${this.currentStoragePlan}`;
    }
  }

  openSubscriptionModal(e) {
    this.cancelSubscriptionTarget.dataset.plan = e.currentTarget.dataset.plan;
    this.cancelSubscriptionTarget.dataset.order = e.currentTarget.dataset.order;
  }

  async checkPromo() {
    const coupon = this.promoFieldTarget.value;
    try {
      const { data } = await axios.post(
        "/subscription/check-promocode",
        coupon
      );

      const value = data.promocodeInfo?.value;
      const isActive = data.promocodeInfo?.isActive;
      const limit = data.promocodeInfo?.limit;
      if (value && isActive && limit > 0) {
        this.promocodeInfo = data.promocodeInfo;

        const yearOrMonthToggle = Object.keys(
          this.promocodeInfo.products
        ).reduce((acc, item) => (acc += item.includes("month") ? 1 : -1), 0);
        if (yearOrMonthToggle < 0) {
          this.switchYearMonthTarget.checked = false;
          this.switchYearMonthTarget.removeAttribute("checked");
          this.toggleSwitchPlans(false);
        } else if (yearOrMonthToggle > 0) {
          this.switchYearMonthTarget.checked = true;
          this.switchYearMonthTarget.setAttribute("checked", "");
          this.toggleSwitchPlans(true);
        }

        this.editPromoFormTarget.querySelector("#promoValue").innerText =
          coupon;
        this.promoFormTarget.classList.add("d-none");
        this.editPromoFormTarget.classList.remove("d-none");
        this.updateCouponPrices();
        this.changeMultisubPlan();
      } else if (isActive === false || !data.promocodeInfo.length) {
        this.noActivePromoTarget.classList.remove("d-none");
        setTimeout(() => {
          this.noActivePromoTarget.classList.add("d-none");
        }, 3000);
      }
    } catch (err) {
      this.wrongPromoTarget.classList.remove("d-none");
      setTimeout(() => {
        this.wrongPromoTarget.classList.add("d-none");
      }, 3000);
    }
  }

  updateCouponPrices() {
    if (Object.keys(this.promocodeInfo).length === 0) {
      return;
    }
    const value = this.promocodeInfo.value;
    const products = this.promocodeInfo.products;
    const coupon = this.promoFieldTarget.value;
    const symbol = this.symbol[this.currencySelectTarget.value];

    const liteYearPrice = this.plansData["lite_year"].find(
      (item) => item.currency === this.currencySelectTarget.value
    ).amount;
    const premiumYearPrice = this.plansData["premium_year"].find(
      (item) => item.currency === this.currencySelectTarget.value
    ).amount;
    const liteMonthPrice = this.plansData["lite_month"].find(
      (item) => item.currency === this.currencySelectTarget.value
    ).amount;
    const premiumMonthPrice = this.plansData["premium_month"].find(
      (item) => item.currency === this.currencySelectTarget.value
    ).amount;

    const liteYearDiscount = parseInt(
      this.saveLiteTarget.innerText.match(/\d+/)[0],
      10
    );
    const premiumYearDiscount = parseInt(
      this.savePremiumTarget.innerText.match(/\d+/)[0],
      10
    );

    if (products["lite_year"]) {
      document.querySelector("#liteYear").innerText = `${symbol}${(
        liteYearPrice -
        (liteYearPrice * value) / 100
      ).toFixed(2)}`;

      this.saveLiteTarget.innerText = `${symbol}${(
        liteYearDiscount +
        (liteYearPrice * value) / 100
      ).toFixed(2)}`;
    }
    if (products["premium_year"]) {
      if (!this.multisubValue) {
        this.yearCostBtnTarget.dataset.middlewareUrl += "&coupon=" + coupon;
      }
      document.querySelector("#premiumYear").innerText = `${symbol}${(
        premiumYearPrice -
        (premiumYearPrice * value) / 100
      ).toFixed(2)}`;

      this.savePremiumTarget.innerText = `${symbol}${(
        premiumYearDiscount +
        (premiumYearPrice * value) / 100
      ).toFixed(2)}`;
    }
    if (products["lite_month"]) {
      document.querySelector("#liteMonth").innerText = `${symbol}${(
        liteMonthPrice -
        (liteMonthPrice * value) / 100
      ).toFixed(2)}`;
    }
    if (products["premium_month"]) {
      if (!this.multisubValue) {
        this.monthCostBtnTarget.dataset.middlewareUrl += "&coupon=" + coupon;
      }
      document.querySelector("#premiumMonth").innerText = `${symbol}${(
        premiumMonthPrice -
        (premiumMonthPrice * value) / 100
      ).toFixed(2)}`;
    }
    this.coupon = coupon;
  }

  editPromo() {
    this.resetPremiumCouponLink();
    this.coupon = "";
    this.promocodeInfo = {};
    this.editPromoFormTarget.classList.add("d-none");
    this.editPromoFormTarget.querySelector("#promoValue").innerText = "";
    this.promoFormTarget.classList.remove("d-none");
    this.setData(this.currentCurrency);
    this.changeMultisubPlan();
  }

  async changeMultisubPlan() {
    if (!this.multisubValue) {
      return;
    }
    this.subscribeMultisubBtnTarget.classList.add("disabled");
    const selectedOption =
      this.selectPeriodTarget.options[this.selectPeriodTarget.selectedIndex];
    const requestData = {
      type_key: `${this.selectPlanTarget.value}_${selectedOption.dataset.type}`,
      currency: this.currentCurrency,
      quantity: Number(selectedOption.dataset.quantity),
    };
    if (this.coupon) {
      requestData.coupon = this.coupon;
    }
    try {
      const { data } = await axios.post(
        "/api/stripe/get-subscription-amount",
        requestData
      );
      this.selectPlanPriceTarget.innerHTML = `${
        this.symbol[this.currentCurrency]
      }${data.data.amount}`;

      this.selectPlanPriceWithoutCouponTarget.innerHTML = data.data
        .amount_without_discount
        ? `${this.symbol[this.currentCurrency]}${
            data.data.amount_without_discount
          }`
        : "";
    } catch (err) {
    } finally {
      this.subscribeMultisubBtnTarget.classList.remove("disabled");
    }
  }

  changeStorageMultiSubPeriod(e) {
    this.selectStoragePeriodTargets.forEach((item) => {
      item.selectedIndex = e.target.selectedIndex;
    });
    this.changeStorageMultiSub();
  }

  changeStorageMultiSub() {
    const selectedOption =
      this.selectStoragePeriodTarget.options[
        this.selectStoragePeriodTarget.selectedIndex
      ];
    const price =
      this.plansData[
        this.storageMultiSub[`${selectedOption.dataset.type}Key`]
      ]?.find((item) => item.currency === this.currentCurrency).amount || 0;

    this.storageMultiSubPriceTargets.forEach((item) => {
      item.innerHTML = `${this.symbol[this.currentCurrency]}${(
        price * selectedOption.dataset.quantity
      ).toFixed(2)}`;
    });
  }

  checkoutMultiSub(e) {
    const selectPeriod =
      this.selectPeriodTarget.options[this.selectPeriodTarget.selectedIndex];
    if (this.selectPlanTarget.value === "lite") {
      this.liteMiddlewareUrl = `${
        e.target.dataset[`middleware${this.selectPlanTarget.value}Url`]
      }?type=lite_${selectPeriod.dataset.type}&quantity=${
        selectPeriod.dataset.quantity
      }`;
      if (!e.target.dataset.isuserentries) {
        this.checkoutLite();
        return;
      }
      this.liteQrSelectModal.show();
      this.isInitModalAsSubscribeMode = true;
      let loaded = this.liteQrSelectModalTarget.dataset.loaded;
      if (loaded != 1) {
        this.loadQrSelectModalContent({ qrId: null, isChangeQrAction: false });
      } else {
        this.buyLiteBtnTarget.classList.remove("d-none");
      }
    } else {
      let paymentLink =
        e.target.dataset[`middleware${this.selectPlanTarget.value}Url`] +
        `?currency=${this.currentCurrency}&type=premium_${selectPeriod.dataset.type}&quantity=${selectPeriod.dataset.quantity}`;
      const coupon = this.getPremiumCouponGetParam(paymentLink);
      window.location.href = `${paymentLink}${coupon}`;
    }
  }

  getPremiumCouponGetParam(url) {
    const isActive = this.promocodeInfo?.isActive;
    const limit = this.promocodeInfo?.limit;
    let coupon = "";
    if (isActive && limit > 0) {
      if (
        (/type=premium_year/.test(url) &&
          this.promocodeInfo.products.premium_year) ||
        (/type=premium_month/.test(url) &&
          this.promocodeInfo.products.premium_month)
      ) {
        coupon = this.coupon ? `&coupon=${this.coupon}` : "";
      }
    }
    return coupon;
  }

  multiSubscribeNavigate() {
    document.cookie = "multiSub=1";
    window.location.reload();
  }

  regularSubscribeNavigate() {
    document.cookie = "multiSub=0";
    window.location.reload();
  }

  async searchQr(e) {
    this.currentStepModalData = 1;
    this.totalStepCountModalData = 1;
    this.loadQrSelectModalContent({
      qrId: this.isInitModalAsSubscribeMode ? null : this.qrIdLiteModal,
      isChangeQrAction: !this.isInitModalAsSubscribeMode,
      searchParam: e.target.value,
    });
  }
}
